import React from "react";
import theme from "theme";
import { Theme, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Предложения | Eventure Bliss
			</title>
			<meta name={"description"} content={"Воплощение вашего замысла в жизнь"} />
			<meta property={"og:title"} content={"Предложения | Eventure Bliss"} />
			<meta property={"og:description"} content={"Воплощение вашего замысла в жизнь"} />
			<meta property={"og:image"} content={"https://etherealidyl.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://etherealidyl.com/img/5364634634.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://etherealidyl.com/img/5364634634.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://etherealidyl.com/img/5364634634.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://etherealidyl.com/img/5364634634.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://etherealidyl.com/img/5364634634.png"} />
			<meta name={"msapplication-TileImage"} content={"https://etherealidyl.com/img/5364634634.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Content-6">
			<Override
				slot="SectionContent"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				width="100%"
				min-width="100%"
				lg-grid-template-columns="1fr"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				background="rgba(0, 0, 0, 0) url(https://etherealidyl.com/img/3.jpg) 50% 50% /cover repeat scroll padding-box"
				lg-height="600px"
				md-height="500px"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				flex-direction="column"
				padding="80px 60px 80px 60px"
				sm-padding="50px 25px 80px 25px"
			>
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
					О нас
				</Text>
				<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.2 --fontFamily-sans" color="#505257">
				Добро пожаловать на страницу услуг Eventure Bliss, где мы воплощаем ваши мечты в реальность с помощью экспертного планирования и проведения мероприятий. Мы предлагаем широкий спектр услуг, призванных сделать ваше мероприятие беспроблемным, запоминающимся и необычным. Позвольте нам рассказать вам о наших предложениях и показать, как мы можем сделать ваше следующее мероприятие по-настоящему особенным.
				</Text>
				<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
				Знакомство с нашими услугами
				</Text>
				<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#505257">
				В Eventure Bliss мы гордимся тем, что предоставляем исключительный сервис и уделяем непревзойденное внимание деталям. Наша цель - гарантировать, что каждый аспект вашего мероприятия будет тщательно спланирован и выполнен до совершенства. От интимных встреч до грандиозных торжеств - у нас есть опыт и ресурсы, чтобы воплотить ваше видение в жизнь. Узнайте о широком спектре наших услуг и о том, как мы можем адаптировать их к вашим уникальным потребностям.
				</Text>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h2"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Планирование и организация мероприятий
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
					Индивидуальное планирование мероприятий: 
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Наша команда работает в тесном сотрудничестве с вами, чтобы понять ваше видение и создать индивидуальный план, отражающий ваш стиль и предпочтения.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Первичная консультация: 
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Мы начинаем с подробной консультации, чтобы понять ваши цели, бюджет и видение мероприятия.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Планирование и координация: 
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Наши планировщики координируют каждую деталь, от выбора места проведения до окончательного оформления, обеспечивая плавный и беспроблемный процесс.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Координация в день мероприятия: 
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					В день мероприятия наши координаторы будут на месте, чтобы управлять всеми деталями, позволяя вам расслабиться и наслаждаться праздником.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text
							as="h4"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							В Eventure Bliss мы стремимся воплотить ваше видение в жизнь с помощью наших экспертных услуг и преданной команды. Планируете ли вы интимную встречу или грандиозное торжество, у нас есть навыки и ресурсы, чтобы сделать ваше мероприятие незабываемым. Давайте работать вместе, чтобы создать необыкновенный опыт, который вы и ваши гости будете лелеять вечно.
						</Text>
						<Text as="h2" margin="12px 0" font="--headline2" md-font="--headline3">
						Создадим незабываемые воспоминания
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://etherealidyl.com/img/4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://etherealidyl.com/img/5.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://etherealidyl.com/img/6.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});